import React from "react";
import "./DeviceSettingsModal.scss";

import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";

import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { addDays, setHours, setMinutes } from "date-fns";

// Importing icons
import conveyor_icon from '../../assets/icons/conveyor_belt.png';
import close_icon from '../../assets/icons/close.svg';

export default function DeviceSettingsModal({ handleClose }) {
    const { currentUser } = React.useContext(AuthContext);
    const [ selectedDevice, setSelectedDevice ] = React.useState(currentUser?.device_id);

    async function saveAndClose() {
        if (selectedDevice === currentUser?.info?.selected_device_id) {
            handleClose();
            return;
        }

        await updateDoc(doc(firestore, "users", currentUser.uid), {selected_device_id: selectedDevice});
        handleClose();
        // Refresh the page to apply changes
        window.location.reload(false);
    }

    return (
        <div className="modal">
            <div className="modal-content">
                <div className="modal-header">
                    <p className="modal-title">Band Auswahl</p>
                    <div className="modal-close-button" onClick={handleClose}>
                        <img src={close_icon}/>
                    </div>
                </div>
                <div className="device-settings-center">
                    <img className="device-settings-icon" src={conveyor_icon}/>
                    <p>Wähle das zu beschickende Band aus</p>
                    <select className="device-select" value={selectedDevice} onChange={(e) => setSelectedDevice(e.target.value)}>
                        {Object.entries(currentUser?.devices ?? {}).map(([key, value]) => {
                            return <option key={key} value={key}>{value?.display_name ?? key}</option>
                        })
                        }
                    </select>
                </div>
                <div className="modal-footer">
                    <button className="settings-modal-button" onClick={saveAndClose}>Speichern</button>
                </div>
            </div>
        </div>
    )
}